.feed-state-control button {
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}

.feed-state-control button:hover:not(:disabled) {
  transform: scale(1.02);
}

.feed-state-control button:active:not(:disabled) {
  transform: scale(0.98);
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.feed-state-control {
  animation: slideIn 0.3s ease-out;
}

/* Loading state animation */
.feed-state-control button:disabled {
  position: relative;
  overflow: hidden;
}

.feed-state-control button:disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    transparent,
    rgba(255, 255, 255, 0.2),
    transparent
  );
  animation: loading 1.5s infinite;
}

@keyframes loading {
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
} 